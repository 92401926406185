/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { navigate } = require("gatsby")
require("typeface-open-sans")
require("typeface-lato")

// exports.wrapPageElement = require(`./wrap-page`)
// exports.wrapRootElement = require(`./wrap-root`)

exports.shouldUpdateScroll = () => !window.__tl_inTransition

exports.onPreRouteUpdate = ({ location }) => {
  // prevent the back button during transitions as it breaks pages
  if (
    window.__tl_inTransition &&
    location.pathname !== window.__tl_desiredPathname
  ) {
    window.__tl_back_button_pressed = true
    navigate(window.__tl_desiredPathname)
  }
}
